exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-animation-js": () => import("./../../../src/pages/animation.js" /* webpackChunkName: "component---src-pages-animation-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-sent-error-index-js": () => import("./../../../src/pages/contact/sent/error/index.js" /* webpackChunkName: "component---src-pages-contact-sent-error-index-js" */),
  "component---src-pages-contact-sent-index-js": () => import("./../../../src/pages/contact/sent/index.js" /* webpackChunkName: "component---src-pages-contact-sent-index-js" */),
  "component---src-pages-drawings-a-calendar-without-days-index-js": () => import("./../../../src/pages/drawings/a-calendar-without-days/index.js" /* webpackChunkName: "component---src-pages-drawings-a-calendar-without-days-index-js" */),
  "component---src-pages-drawings-index-js": () => import("./../../../src/pages/drawings/index.js" /* webpackChunkName: "component---src-pages-drawings-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paintings-dry-lands-and-the-deadmans-drought-index-js": () => import("./../../../src/pages/paintings/dry-lands-and-the-deadmans-drought/index.js" /* webpackChunkName: "component---src-pages-paintings-dry-lands-and-the-deadmans-drought-index-js" */),
  "component---src-pages-paintings-index-js": () => import("./../../../src/pages/paintings/index.js" /* webpackChunkName: "component---src-pages-paintings-index-js" */),
  "component---src-pages-paintings-second-hand-daylight-index-js": () => import("./../../../src/pages/paintings/second-hand-daylight/index.js" /* webpackChunkName: "component---src-pages-paintings-second-hand-daylight-index-js" */),
  "component---src-pages-paintings-the-eastern-rain-index-js": () => import("./../../../src/pages/paintings/the-eastern-rain/index.js" /* webpackChunkName: "component---src-pages-paintings-the-eastern-rain-index-js" */)
}

